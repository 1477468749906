import WebServiceRequest from '../Api/WebServiceRequest'

class ServiceCommentGetByProductIdForAdmin extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ServiceComment/GetByServiceIdForAdmin')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class ServiceCommentGetAllForAdmin extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ServiceComment/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class DeleteServiceComment extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ServiceComment/DeleteServiceComment')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class ChangeServiceCommentActivation extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ServiceComment/ChangeCommentActivation')
    }
    setData(data){
        super.setRequestParamDataObj(data)
    }
}
export  {
    ServiceCommentGetByProductIdForAdmin,
    ServiceCommentGetAllForAdmin,
    DeleteServiceComment,
    ChangeServiceCommentActivation
};
